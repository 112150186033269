#newsletter {
    display: flex;
    align-items: center;
    justify-content: center;
    background: #11191f;

    #form {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        width: 90%;
        gap: 10px;
    }

    .input-suscribe {
        border: 1px solid lightcoral;
        background: rgb(255, 228, 228);
    }

    .btn-suscribe {
        width: 100%;
        text-transform: capitalize;
        font-size: 1.3rem;
        color: lightcoral;
        border: 1px solid lightcoral;
        background: transparent;
        padding: 10px 20px;
        border-radius: 5px;
        cursor: pointer;
        transition: all 0.3s ease;
    }

    @media (min-width: 768px) {
        #form {
            width: 30%;
        }
    }

    @keyframes smooth-appear {
        to {
            bottom: 20px;
            opacity: 1;
        }
    }

    .container {
        background: transparent;
        border: 1px solid #fff;
        color: white;
        padding: 20px;
        position: fixed;
        bottom: -90%;
        opacity: 0;
        left: 50%;
        transform: translateX(-50%);
        border-radius: 10px;
        animation: smooth-appear 1s ease forwards;
        width: 90%;
    }


    .active {
        display: block !important;
    }

    .hidden {
        display: none;
    }
}