#projects {
  background: linear-gradient(135deg, #81fbb8 10%, #28c76f 100%);
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 100px;

  .project-box {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 15px 5px;
    height: 130px;
    text-align: center;
    border-radius: 15px;
    margin: 10px;
    cursor: pointer;
    color: white;
    font-weight: 800;
    letter-spacing: 1px;
    text-shadow: 1px 1px 1px grey;
    box-shadow: 2px 2px 5px grey;
  }

  @media (max-width: 768px) {
    .project-box {
      height: 80px;
    }
  }

  .project-box:hover {
    background: #54b3d6;
    border: 1px solid white;
    transition: all 0.1s ease-in-out;
  }

  .red {
    background-image: linear-gradient(135deg, #feb692 10%, #ea5455 100%);
  }

  .lila {
    background-image: linear-gradient(135deg, #ce9ffc 10%, #7367f0 100%);
  }

  .cold {
    background-image: linear-gradient(135deg, #ff96f9 10%, #c32bac 100%);
  }

  .sun {
    background-image: linear-gradient(135deg, #eddd79 10%, #b2ba41 100%);
  }

  .blue {
    background-image: linear-gradient(135deg, #72edf2 10%, #a4a4df 100%);
  }
}
