/* Tooltip container */
.tooltip-A {
  position: relative;
  display: inline-block;
}

/* Tooltip text */
.tooltip-A .tooltiptext-A {
  display: none;
  width: 120px;
  background-color: grey;
  color: white;
  text-align: center;
  padding: 5px 3px;
  border-radius: 6px;

  /* Position the tooltip */
  position: absolute;
  z-index: 1;

  // // top
  // width: 120px;
  // bottom: 100%;
  // left: 50%;
  // margin-left: -60px; /* Use half of the width (120/2 = 60), to center the tooltip */

  // //left
  // top: -5px;
  // right: 105%;
}

/* Show the tooltip text when you mouse over the tooltip container */
.tooltip-A:hover .tooltiptext-A {
  display: inline-block;
}
