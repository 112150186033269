button:active,
button:focus,
a:active,
a:focus,
input:focus,
input:active,
*:focus,
*:active {
  outline: 0;
  -moz-outline-style: none;
  box-sizing: border-box;
  word-wrap: break-word;
}

body {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  font-family: "Muli", sans-serif;
}

.page {
  width: 100%;
  height: 100vh;
  padding: 50px 20px;
}

h2 {
  font-size: $xxgiant-font;
  padding: 0 0 0.5rem 0;
}

p {
  font-size: $semi-big-font;
}

.active {
  font-weight: 800;
}

.inactive {
  text-decoration: none;
}
