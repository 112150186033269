#contacto {
  color: white;
  background: grey;
  background-image: linear-gradient(135deg, #81fbb8 10%, #28c76f 100%);

  .titles {
    padding: 1em 0;
    color: rgb(45, 45, 45);
    letter-spacing: 1px;

    .description {
      p {
        text-align: center;
      }
    }
  }

  .contenido_contacto {
    display: flex;
    justify-content: center;
  }

  .contenido_contacto .formulario {
    width: 95%;
  }

  .contenido_contacto .formulario {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 5px;
  }

  .contenido_contacto .formulario input {
    width: 50%;
    margin-bottom: 20px;
    text-align: left;
    color: grey;
  }

  .contenido_contacto .formulario input,
  .contenido_contacto .formulario textarea {
    border: 1px solid lightgrey;
    border-radius: 5px;
    resize: none;
    padding: 10px;
    margin: 5px;
    font-size: 1em;
    text-align: left;
    color: grey;
    // text-shadow: 1px 1px 2px grey;
    background: white;
  }

  .contenido_contacto .formulario input.este_no,
  .contenido_contacto .formulario textarea {
    width: 100%;
  }

  #send {
    margin-top: 25px;
    width: 100%;
    background: black;
    border: none;
    border-radius: 5px;
    border-width: 0;
    border-style: solid;
    transition: all 0.2s ease-in-out;
    color: white;
    font-size: 1em;
    text-transform: uppercase;
    text-align: center;
    font-weight: 400;
    display: inline-block;
    padding: 13px 18px;
    margin: 4px 0;
    cursor: pointer;
  }

  #send:hover {
    background: white;
    color: black;
  }
}

@media (max-width: 768px) {
  #contacto {
    .sub-title {
      width: 95%;
    }
  }
}
