// https://medium.com/@katiemctigue/how-to-create-a-dark-mode-in-sass-609f131a3995

// ***************** Text Size

$xsmall-font: 0.6667rem;
$xssmall-font: 0.75rem;
$small-font: 0.8333rem;
$semi-small-font: 0.9167rem;
$default-font: 1rem;
$default-xl-font: 1.1rem;
$semi-big-font: 1.167rem;
$semixl-big-font: 1.25rem;
$big-font: 1.333rem;
$xsbig-font: 1.4rem;
$xbig-font: 1.5rem;
$semi-giant-font: 1.667rem;
$giant-font: 1.833rem;
$xsgiant-font: 1.9rem;
$xgiant-font: 2rem;
$xsxgiant-font: 2.2rem;
$xxgiant-font: 2.5rem;

// ***************** Colors

$btn-color: white;
$btn-bg: lightcoral;
