html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
body {
  line-height: 1;
}
ol,
ul {
  list-style: none;
  list-style-type: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}

// ** reset

html,
body {
  margin: 0;
  padding: 0;
  border: 0;
  font: inherit;
  box-sizing: border-box;
  word-wrap: break-word;
  color: grey;
}

h1 {
  font-weight: 800 !important;
  font-size: 8vw !important;
  padding: 20px !important;
}

h2 {
  font-weight: 800;
  font-size: 2em;
  margin: 20px 0 15px 0;
  // padding: 10px;
}

h3 {
  text-transform: uppercase;
  font-weight: 600;
  font-size: 1.3em;
  margin: 0;
  padding: 3px;
}

h4 {
  font-weight: 400;
  font-size: 1.8em;
  margin: 0;
  padding: 10px;
}

p {
  word-wrap: break-word;
  font-size: 1.3em;
  margin: 0;
  padding: 5px;
  text-align: center;
}

i {
  color: white;
}

strong {
  font-weight: 600;
}

section {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  min-height: 100vh;
}

button {
  background: black;
  border: none;
  border-radius: 3px;
  border-width: 0;
  border-style: solid;
  transition: all 0.2s ease-in-out;
  color: white;
  font-size: 1em;
  text-transform: uppercase;
  font-weight: 400;
  display: inline-block;
  padding: 13px 18px;
  margin: 4px 0;
}

button:hover {
  background: white;
  color: black;
  box-shadow: inset 0 0 0 1px #3a7999;
}
