.header-component {
  position: absolute;
  width: 100%;

  .navbar-expand-lg {
    background: white;
    box-shadow: 1px 1px 4px lightgrey;
    z-index: 10;

    .navbar-toggler {
      color: rgb(56, 56, 56);
    }
  }

  .nav-item {
    font-size: 1.2em;
    text-transform: lowercase;
    color: rgb(56, 56, 56);
    margin: 0 10px;
    cursor: pointer;
  }
}

@media (max-width: 768px) {
  #navbarSupportedContent {
    justify-content: center;
    color: white;
  }

  header .navbar,
  header .navbar-expand-lg {
    z-index: 10;
  }
}
