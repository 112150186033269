#home {
  background: linear-gradient(135deg, #81fbb8 10%, #28c76f 100%);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  .brand {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    padding: 0 5vw;
    margin: 0 1vw;
    color: white;
    text-shadow: 1px 1px 30px ligthgrey;
    border-bottom: 2px solid white;
  }

  .col-left {
    padding: 0 2vw;

    li {
      margin: 2vh 0;
      font-size: 3.7em;
      color: white;
      text-shadow: 1px 1px 30px ligthgrey;
      cursor: pointer;
    }
  }
}

@media (max-width: 768px) {
  #home {
    flex-direction: column;
    justify-content: center;

    .brand {
      margin: 0;
      display: flex;
      align-items: center;

      h1.name {
        font-size: 11vw !important;
        width: 100%;
        margin: 0 !important;
        padding: 0 !important;
      }
    }

    .col-left {
      padding: 0;

      li {
        margin: 3vh 0;
        font-size: 3.5em;
      }
    }
  }
}
